import { openDB } from 'idb';

const escasesDb = 'EscasesLocalStorageDb';
const caseNoteTable = 'CaseNoteTable';
const intakeNoteTable = 'intakeNoteTable';

const dbPromise = openDB(escasesDb, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(caseNoteTable)) {
      const store = db.createObjectStore(caseNoteTable, { keyPath: 'clientId' });
      store.createIndex('clientIdx', 'clientId', { unique: false });
      store.createIndex('servicePlanHeaderIdx', 'servicePlanHeaderId', { unique: false });
      store.createIndex('programIdx', 'programId', { unique: false });
    }
    if (!db.objectStoreNames.contains(intakeNoteTable)) {
      const store = db.createObjectStore(intakeNoteTable, { keyPath: 'clientId' });
      store.createIndex('clientIdx', 'clientId', { unique: false });
      store.createIndex('servicePlanHeaderIdx', 'servicePlanHeaderId', { unique: false });
    }
  },
});

export const saveCaseNote = async caseNote => {
  if (!caseNote || !caseNote.clientId) return;
  const db = await dbPromise;
  await db.put(caseNoteTable, {
    ...caseNote,
    clientId: Number(caseNote.clientId),
  });
};

export const loadCaseNote = async clientId => {
  if (!clientId) return null;
  clientId = Number(clientId);
  const db = await dbPromise;
  const tx = db.transaction(caseNoteTable, 'readonly');
  const store = tx.objectStore(caseNoteTable);
  const index = store.index('clientIdx');
  const matchCaseNotes = await index.getAll(clientId);
  return matchCaseNotes.find(node => node?.clientId === clientId) || null;
};

export const deleteCaseNote = async clientId => {
  if (!clientId) return null;
  clientId = Number(clientId);
  console.log('clientId: ', clientId);
  const db = await dbPromise;
  const tx = db.transaction(caseNoteTable, 'readwrite');
  const store = tx.objectStore(caseNoteTable);
  const index = store.index('clientIdx');
  const matchCaseNotes = await index.getAll(clientId);
  const caseNoteToDelete = matchCaseNotes.find(node => node?.clientId === clientId) || null;

  if (caseNoteToDelete) {
    await store.delete(caseNoteToDelete.clientId); // Delete by primary key (id)
    await tx.done;
    return true;
  }

  return false;
};

const saveIntakeNote = async intakeNote => {
  if (!intakeNote || !intakeNote.clientId) return;
  const db = await dbPromise;
  await db.put(intakeNoteTable, {
    ...intakeNote,
    clientId: Number(intakeNote.clientId),
  });
};

const getIntakeNote = async clientId => {
  if (!clientId) return null;
  clientId = Number(clientId);
  const db = await dbPromise;
  const tx = db.transaction(intakeNoteTable, 'readonly');
  const store = tx.objectStore(intakeNoteTable);
  const index = store.index('clientIdx');
  const intakeNotes = await index.getAll(clientId);
  return intakeNotes.find(node => node?.clientId === clientId) || null;
};

const deleteIntakeNote = async clientId => {
  if (!clientId) return null;
  clientId = Number(clientId);
  const db = await dbPromise;
  const tx = db.transaction(intakeNoteTable, 'readwrite');
  const store = tx.objectStore(intakeNoteTable);
  const index = store.index('clientIdx');
  const intakeNotes = await index.getAll(clientId);
  const intakeNoteToDelete = intakeNotes.find(node => node?.clientId === clientId) || null;

  if (intakeNoteToDelete) {
    await store.delete(intakeNoteToDelete.clientId); // Delete by primary key (id)
    await tx.done;
    return true;
  }
  return false;
};

export const escasesLocalStorageDb = {
  saveCaseNote,
  loadCaseNote,
  deleteCaseNote,
  saveIntakeNote,
  getIntakeNote,
  deleteIntakeNote,
};
